import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import {
    TransformWrapper,
    TransformComponent,
} from "react-zoom-pan-pinch";
import "./css/style.css";
import { Tooltip } from "react-tooltip";
import axios from "axios";

export default function InteraktivMap(params) {
    const [data, setData] = useState(null);

    useEffect(() => {
        axios
            .get("/data/dataBase.json")
            .then((response) => {
                let list = extractListItems(response.data.rent)
                console.log(list);
                
                setData(list);
            })
            .catch((error) => {
                console.error("Error fetching the JSON data:", error);
            });

    }, [params.categor]);
    const extractListItems = (rentObject) => {
        return Object.values(rentObject) // Получаем массив значений всех ключей
            .flatMap(category => category.type || []) // Получаем массив `type` для каждой категории, если он существует
            .flatMap(type => type.list || []) // Получаем массив `list` для каждого `type`, если он существует
            .map(item => ({ // Добавляем уникальный ключ `id` для каждого элемента
                ...item,
                id: `${item.typeTitle}_${item.title}` // Формируем `id` из `typeTitle` и `title`
            }));
    };
    if (!data) {
            return (
                <Container className="container-sm">
                    Loading...
                </Container>
            );
        }
    return (
        <section className="interaktivMap">
            <Container className="container-sm">
                <h1 className="h3">
                    *Карта находится на этапе разработки{" "}
                    
                </h1>
                <div className="interaktivMap-map">
                    <TransformWrapper
                        initialScale={1}
                        minScale={1}
                        maxScale={5}
                        className="interaktivMap-a"
                    >
                        <TransformComponent className="interaktivMap-b">
                            <div className="interaktivMap-c">
                                <img
                                    src="/img/map/mapS.png"
                                    alt="Интерактивная карта"
                                    useMap="#map"
                                    className="interaktivMap-basa"

                                />
<svg className="interaktivMap-mask"  viewBox="0 0 1289 937" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect id="" width="78.6863" height="53.073" transform="translate(550.584 364.999)"/>
<rect width="78.6869" height="53.0721" transform="translate(587.188 403.81)"/>
<rect width="78.6867" height="53.0737" transform="translate(626.254 448.204)"/>
<rect width="78.6882" height="53.0708" transform="translate(666.043 494.218)"/>
<rect width="78.6873" height="53.0732" transform="translate(695.573 536.924)"/>
<rect width="78.6878" height="53.0727" transform="translate(782.565 654.3)"/>
<rect width="78.6885" height="53.0716" transform="translate(831.2 697.663)"/>
<rect width="83.4727" height="60.0228" transform="translate(742.456 598.461)"/>
<rect width="47.5715" height="32.475" transform="translate(269 215.458)"/>
<rect width="47.5706" height="32.475" transform="translate(278.437 235.907)"/>
<rect width="47.571" height="32.475" transform="translate(294.953 257.926)"/>
<rect width="47.572" height="32.475" transform="translate(306.749 278.375)"/>
<rect width="47.5736" height="32.4752" transform="translate(324.842 310.619)"/>
<rect width="83.4718" height="60.0212" transform="translate(454.608 357.021)"/>
<rect width="78.6876" height="53.0739" transform="translate(468.766 413.646)"/>
<rect width="83.4746" height="60.0195" transform="translate(530.1 452.967)"/>
<rect width="78.6879" height="53.0708" transform="translate(604.814 705.422)"/>
<rect width="78.687" height="53.0727" transform="translate(643.354 736.093)"/>
<rect width="78.6851" height="53.2273" transform="translate(556.064 603.967)"/>
<rect width="78.6867" height="53.2263" transform="translate(474.268 579.586)"/>
<rect width="78.688" height="53.2272" transform="translate(490.783 629.135)"/>
<rect width="78.6832" height="53.2259" transform="translate(548.201 655.086)"/>
<rect width="78.6871" height="53.0735" transform="translate(390.905 452.18)"/>
<rect width="83.4688" height="60.0214" transform="translate(422.367 509.592)"/>
<rect width="78.6869" height="53.0735" transform="translate(515.95 528.466)"/>
<rect width="63.4282" height="34.7962" transform="translate(338.213 348.366)"/>
<rect width="62.6537" height="34.7665" transform="translate(357.873 376.679)"/>
<rect width="60.887" height="34.7001" transform="translate(372.814 404.205)"/>
<rect width="83.4712" height="60.0205" transform="translate(660.663 328.708)"/>
<rect width="83.469" height="60.0197" transform="translate(708.634 377.466)"/>
<rect width="78.6885" height="53.0719" transform="translate(755.818 427.8)"/>
<rect width="78.6873" height="53.0723" transform="translate(797.505 475.774)"/>
<rect width="78.6875" height="53.0727" transform="translate(842.334 520.603)"/>
<rect width="78.6858" height="53.0729" transform="translate(882.445 559.926)"/>
<rect width="78.6862" height="53.0732" transform="translate(945.359 619.696)"/>
<rect width="88.2944" height="45.3667" transform="translate(468.771 184)"/>
<rect width="83.4681" height="60.02" transform="translate(502.587 211.525)"/>
<rect width="83.4726" height="60.0179" transform="translate(533.254 243.773)"/>
<rect width="83.4704" height="60.0168" transform="translate(603.255 276.803)"/>
<rect width="98.473" height="44.3388" transform="translate(352.366 186.358)"/>
<rect width="83.4722" height="60.0186" transform="translate(390.114 220.001)"/>
<rect width="83.4719" height="60.0194" transform="translate(425.807 267.363)"/>
</svg>


                            </div>
                        </TransformComponent>
                    </TransformWrapper>
               
                </div>
            </Container>
        </section>
    );
}
