import {
    Button,
    CloseButton,
    Modal,
} from "react-bootstrap";
import "./css/style.css";
import { InputMask } from "@react-input/mask";
import { useEffect, useState } from "react";
import { MailGo } from "../../ajax";
import { useAppContext } from "../../route/Context";

function ModalForm(params) {
    const { formTheme } = useAppContext(); // Используем контекст
    console.log(formTheme);
 
    // состояние данных формы
    const [formData, setFormData] = useState({
        name: "",
        tel: "",
        masseg: "",
        theme: "",
    });
    //   состояние отправки
    const [formGo, setFormGo] = useState(false);
    useEffect(() => {
        setFormData({
            ...formData,
            theme: formTheme,
        });
        return () => {
            setFormData({
                ...formData,
                theme: formTheme,
            });
        }
      }, [formTheme])
      
    //   обработка событий ввода и фиксирование их состояния
    const handleChange = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    // Обработчик отправки формы
    const handleSubmit = async (e) => {
        const form = e.currentTarget;

        if (form.checkValidity() === false) {
            e.preventDefault();
            e.stopPropagation();
        } else {
            e.preventDefault();
        
            const isLoggedIn = await MailGo(formData);

            if (isLoggedIn) {
                // Выполните действия, если вход успешен
               
                setFormGo(true);
                setTimeout(() => {
                    setFormGo(false);
                }, 2000);
            } else {
                // Выполните действия, если вход неуспешен
            }
        }
    };
    return (
        <Modal
            className={"popupFos"}
            {...params}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <div className="popupFos_content">
                <div className="popupFos_content_header flex">
                    <a
                        className="popupFos_content_header_logo"
                        href=""
                    >
                        <img src="/logo.png" alt="" />
                    </a>
                    <a
                        className="popupFos_content_header_local flex t18"
                        href="https://yandex.ru/maps/195/ulyanovsk/house/orenburgskaya_ulitsa_42/YEEYcwRgSkQCQFtufX90dH1kYg==/?ll=48.530315%2C54.355176&source=serp_navig&z=17.14"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img
                            src="/img/icon/local.svg"
                            alt=""
                        />
                        Оренбургская ул., 42А
                    </a>
                </div>
                {!formGo ? (
                    <form
                        className="popupFos_content_form"
                        onSubmit={handleSubmit}
                        onChange={handleChange}
                    >
                        <h3 className="h3">
                            Связаться с нами
                        </h3>
                        <input
                            name="name"
                            className="inp_t t16"
                            type="text"
                            placeholder="Имя*"
                            required
                        />

                        <InputMask
                            mask="+7 (___) ___-__-__"
                            placeholder="Телефон*"
                            name="tel"
                            required
                            className="inp_t t16"
                            replacement={{ _: /\d/ }}
                        />
                        <textarea
                            className="inp_t t16"
                            name="masseg"
                            id=""
                            placeholder="Сообщение"
                        ></textarea>

                        <input type="hidden" name="theme" defaultValue={formTheme} value={formTheme} />
                        <p className="t14">
                            Оставляя контактные данные Вы
                            даёте право на их обработку.
                            Ваши данные не передаются
                            третьим лицам.
                        </p>
                        <div className="flex">
                            <button
                                type="submmit"
                                className="button t16"
                            >
                                заказать звонок
                            </button>
                        </div>
                    </form>
                ) : (
                    <>
                        <div className="popupFos_content_form ok">
                            <h3 className="h3">
                                Спасибо, что выбрали нас!
                            </h3>
                            <p className="t18">
                                Ваша заявка успешно
                                отправлена, ожидайте звонка
                                от нашего менеджера
                            </p>
                            <p className="t18">
                                Приятного отдыха!{" "}
                            </p>
                        </div>
                    </>
                )}
            </div>
            <div className="popupFos_fonImg">
                {" "}
                <CloseButton onClick={params.onHide} />
            </div>
        </Modal>
    );
}

export default ModalForm;
