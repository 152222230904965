import { Breadcrumb, Container } from "react-bootstrap";
import "./css/style.css";
import Title_block from "../../component/title_block";
import { useEffect, useState } from "react";
import { Specifications } from "../../modules/specifications";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { useAppContext } from "../../route/Context";
import ModalForm from "../../modules/popup";
import PopSlider from "../../modules/sliderPop";
import PopFoto from "../../modules/popFoto";

export default function Restaurant() {
    const location = useLocation();
    const { setFormTheme, modalShow, setModalShow } =
        useAppContext(); // Используем контекст
    const [restaran, setrestaran] = useState("#restaurant");
    const [data, setData] = useState(null);
    const [item, setItem] = useState(null);
    const [menu, setMenu] = useState(null);
    const [menuOpen, setMenuOpen] = useState(false);
    const [foto, setFoto] = useState(false);
    const [index, setindex] = useState(0);

    useEffect(() => {
        if (data !== null) {
            setItem(
                data.filter((el) => el.url === restaran)[0]
            );
        } else {
            axios
                .get("/data/dbRestor.json")
                .then((response) => {
                    const hash = location.hash;
                    console.log(hash);

                    setData(response.data.celebrations);
                    if (hash) {
                        setrestaran(hash);
                        setItem(
                            response.data.celebrations.filter(
                                (el) => el.url === hash
                            )[0]
                        );
                    } else {
                        setItem(
                            response.data.celebrations.filter(
                                (el) => el.url === restaran
                            )[0]
                        );
                    }

                    setMenu(response.data.menu);
                })
                .catch((error) => {
                    console.error(
                        "Error fetching the JSON data:",
                        error
                    );
                });
        }
    }, [restaran]);
    useEffect(() => {
        const url = window.location.href;

        // Обновляем существующие метатеги
        const metaDescription = document.querySelector(
            'meta[name="description"]'
        );
        const metaOGTitle = document.querySelector(
            'meta[property="og:title"]'
        );
        const metaOGDescription = document.querySelector(
            'meta[property="og:description"]'
        );
        const metaOGUrl = document.querySelector(
            'meta[property="og:url"]'
        );
        const metaOGIMG = document.querySelector(
            'meta[property="og:image"]'
        );

        if (metaDescription)
            metaDescription.content =
                " Посетив ресторан базы отдыха Иволга, Вы, несомненно, ощутите всю красоту и шарм времен Пушкина. Ресторан оснащен деревянной мебелью из сосны, чучела диких животных, стилизованные люстры и бра создают незабываемую атмосферу 19 века. Обеденный зал готов принять до 100 человек и работает для вас круглосуточно.";
        if (metaOGTitle)
            metaOGTitle.content =
                'Проведение мероприятий | Турбаза в Ульяновске "Иволга"';
        if (metaOGDescription)
            metaOGDescription.content =
                " Посетив ресторан базы отдыха Иволга, Вы, несомненно, ощутите всю красоту и шарм времен Пушкина. Ресторан оснащен деревянной мебелью из сосны, чучела диких животных, стилизованные люстры и бра создают незабываемую атмосферу 19 века. Обеденный зал готов принять до 100 человек и работает для вас круглосуточно.";
        if (metaOGUrl) metaOGUrl.content = url;
        if (metaOGIMG)
            metaOGIMG.content = `https://xn--73-6kchf2awx.xn--p1ai/img/restaurant/banket.jpg`;

        // Обновляем заголовок страницы
        document.title =
            'Проведение мероприятий | Турбаза в Ульяновске "Иволга"';
    }, []);
    const handlePopForm = (index) => {
        setFoto(true);
        setindex(index)
    };
    if (!data) {
        return (
            <Container className="container-sm">
                Loading...
            </Container>
        );
    }
    const hashChekit = (index) => {
        setrestaran(index);
    };
    const hashPopUp = (theme) => {
        setFormTheme(theme);
        setModalShow(true);
    };
    const hashPopMenu = () => {
        setMenuOpen(true);
    };
    return (
        <main className="restaurant">
            <Container className="container-sm">
                <Breadcrumb className="breadcrumbs">
                    <Breadcrumb.Item href="/">
                        Главная
                    </Breadcrumb.Item>
                    <Breadcrumb.Item href="/#banquet">
                        Торжества
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>
                        {item.name}
                    </Breadcrumb.Item>
                </Breadcrumb>
            </Container>

            <section className="sect-us">
                <Container className="container-sm ">
                    <Title_block
                        title={item.title}
                        type={"Услуги"}
                        discription={
                            <>
                                <p
                                    dangerouslySetInnerHTML={{
                                        __html: item.discription,
                                    }}
                                    className="t16"
                                ></p>

                                <div className="menu flex">
                                    {data.map((e, i) => (
                                        <label
                                            data-aos="fade-right"
                                            data-aos-delay={`${
                                                5 + i
                                            }00`}
                                            data-aos-once
                                            key={i}
                                            onClick={() =>
                                                hashChekit(
                                                    e.url
                                                )
                                            }
                                            htmlFor={e.url}
                                            className="menu_item t20"
                                        >
                                            <input
                                                type="radio"
                                                name="restaurant"
                                                id={e.url}
                                                defaultChecked={
                                                    restaran ===
                                                    e.url
                                                }
                                            />
                                            {e.name}
                                        </label>
                                    ))}
                                </div>
                            </>
                        }
                    />

                    <div className="restaurant_info child_info">
                        <div className="child_info-img">
                            <img
                                src={item.imgPrev}
                                alt={
                                    item.name +
                                    " на территори иволги"
                                }
                            />
                        </div>

                        <div className="child_info-content">
                            <div className="child_info-content_text">
                                {item.text.map((e, i) => (
                                    <p
                                        data-aos="fade-down"
                                        data-aos-delay={`${
                                            i * 1
                                        }00`}
                                        data-aos-once
                                        key={i}
                                        className="t16"
                                        dangerouslySetInnerHTML={{
                                            __html: e,
                                        }}
                                    ></p>
                                ))}
                                <a
                                    data-aos="fade-down"
                                    data-aos-delay={`600`}
                                    data-aos-once
                                    className="t16 link"
                                    onClick={hashPopMenu}
                                >
                                    посмотреть меню ↗
                                </a>
                            </div>
                            <div className="child_info-content_menu flex">
                                <button
                                    onClick={() =>
                                        hashPopUp(
                                            "Забранировать ресторан повод ? - " +
                                                item.name
                                        )
                                    }
                                    data-aos="fade-down"
                                    data-aos-delay={`600`}
                                    data-aos-once
                                    className="button"
                                >
                                    ЗАБРОНИРОВАТЬ
                                </button>

                                <button
                                    data-aos="fade-down"
                                    data-aos-delay={`800`}
                                    data-aos-once
                                    onClick={hashPopMenu}
                                    className="button -g"
                                >
                                    меню
                                </button>
                            </div>
                        </div>
                    </div>
                </Container>
            </section>
            <Specifications
                arrSoec={[
                    {
                        nameKat: "Услуги",
                        list: [
                            " Корпоративы",
                            "Свадьбы",
                            "Деловые обеды",
                            "День рожденья",
                        ],
                    },
                    {
                        nameKat: "Бар ",
                        list: [
                            " В продаже пиво, вода",
                            "Доставка итальянской пиццы",
                        ],
                    },
                    {
                        nameKat: "Кухня",
                        list: [
                            " Собственная кухня",
                            "Разнообразное меню",
                            "Деловые обеды",
                            "День рожденья",
                        ],
                    },
                    {
                        nameKat: "Досуг",
                        list: [
                            " Фотозона",
                            "Локации для выездной регистрации",
                            "Собственная набережная",
                        ],
                    },
                ]}
            />
            <PopSlider
                item={menu}
                show={menuOpen}
                onHide={() => setMenuOpen(false)}
            />
            <ModalForm
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
            <section className="sect-us">
                <Container className="grid -g4">
                    {item.galery !== null &&
                        item.galery.map((e, i) => (
                            <div
                                onClick={()=>handlePopForm(i)}
                                key={i}
                                className={`el-set ${
                                    i === 0
                                        ? "-column-2"
                                        : ""
                                }`}
                            >
                                <img src={e} alt="" />
                            </div>
                        ))}
                </Container>
            </section>
            {item.galery !== null && (
                <PopFoto
                activeIndex={index}
                    show={foto}
                    setShow={setFoto}
                    array={item.galery}
                />
            )}
        </main>
    );
}
