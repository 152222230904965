import { useParams } from "react-router-dom";
import { Breadcrumb, Container } from "react-bootstrap";
import { useEffect, useState } from "react";
import axios from "axios";
import Title_block from "../../component/title_block";
import { Specifications } from "../../modules/specifications";
import "./css/style.css";
import { useAppContext } from "../../route/Context";
import ModalForm from "../../modules/popup";
import PopFoto from "../../modules/popFoto";

export default function RentCertain() {
    const params = useParams();
    const {
        setFormTheme,
        modalShow,
        setModalShow,
    } = useAppContext(); // Используем контекст

    const [cat, setCat] = useState(null);
    const [typ, setTyp] = useState(null);
    const [elem, setElem] = useState(null);
    const [foto, setFoto] = useState(false);
    const [index, setindex] = useState(0);

    useEffect(() => {
        axios
            .get("/data/dataBase.json")
            .then((response) => {
                setCat(response.data.rent[params.categor]);

                setTyp(
                    response.data.rent[
                        params.categor
                    ].type.find(
                        (item) =>
                            item.typeTitle === params.type
                    )
                );

                setElem(
                    response.data.rent[params.categor].type
                        .find(
                            (item) =>
                                item.typeTitle ===
                                params.type
                        )
                        .list.find(
                            (item) =>
                                item.title === params.id
                        )
                );
            })
            .catch((error) => {
                console.error(
                    "Error fetching the JSON data:",
                    error
                );
            });
    }, [params.categor]);
    useEffect(() => {
        if (elem != null) {
            // Обновляем существующие метатеги
            const metaDescription = document.querySelector(
                'meta[name="description"]'
            );
            const metaOGTitle = document.querySelector(
                'meta[property="og:title"]'
            );
            const metaOGDescription =
                document.querySelector(
                    'meta[property="og:description"]'
                );
            const metaOGUrl = document.querySelector(
                'meta[property="og:url"]'
            );
            const metaOGIMG = document.querySelector(
                'meta[property="og:image"]'
            );

            if (metaDescription)
                metaDescription.content = elem.description;
            if (metaOGTitle)
                metaOGTitle.content =
                  " " + cat.nameKat + ", " +elem.typeName + " - " +elem.name+ ' | Турбаза в Ульяновске "Иволга"';
            if (metaOGDescription)
                metaOGDescription.content =
                    elem.description;
            if (metaOGUrl) metaOGUrl.content = url;
            if (metaOGIMG)
                metaOGIMG.content = `https://xn--73-6kchf2awx.xn--p1ai${elem.previevImg}`;

            // Обновляем заголовок страницы
            document.title =
                " " + cat.nameKat + ", " +elem.typeName + " - " +elem.name+ ' | Турбаза в Ульяновске "Иволга"';
        }
    }, [elem]);
    if (!elem) {
        return (
            <Container className="container-sm">
                Loading...
            </Container>
        );
    }
    const hashPopUp = (theme) =>{
        setFormTheme(theme)
            setModalShow(true)
    }

    const handlePopForm = (index)=>{
        setFoto(true)
        setindex(index)
    }
  
    const url = window.location.href;

    return (
        <main className="rentCertain">
            <Container className="container-sm">
                <Breadcrumb className="breadcrumbs">
                    <Breadcrumb.Item href="/">
                        Главная
                    </Breadcrumb.Item>
                    <Breadcrumb.Item href="/#habitation">
                        Аренда
                    </Breadcrumb.Item>
                    <Breadcrumb.Item
                        href={`/rent/${params.categor}`}
                    >
                        {cat.nameKat}
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>
                        {elem.typeName}
                    </Breadcrumb.Item>
                </Breadcrumb>
            </Container>

            <section className="rentCertain sect-us">
                <Container className="container-sm ">
                    <Title_block
                        title={elem.name}
                        type={"Услуги"}
                        
                    />

                    <div className="child_info">
                        <div className="child_info-img">
                            <img
                                src={elem.previevImg}
                                alt={`${typ.typeName}-${elem.name}`}
                            />
                        </div>

                        <div className="child_info-content">
                            <div className="child_info-content_text">
                                <p className="t16" dangerouslySetInnerHTML={{__html:elem.description}}>
                                </p>

                                <div className="child_info-content_text_infobox ">
                                    <img
                                        src="/img/icon/krovat.svg"
                                        alt="Время размещения"
                                    />
                                    <div className="">
                                        {elem.check_in !== null ? <> <p className="t16">
                                            Время заезда: в{" "}
                                            {elem.check_in}
                                        </p>
                                        <p className="t16">
                                            Время выезда: в{" "}
                                            {elem.check_out}
                                        </p></>:
                                        <p className="t16">
                                            Круглосуточно
                                        </p>
                                        }
                                       
                                        <a
                                            href="http://"
                                            className="link"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            правила
                                            проживания ↗
                                        </a>
                                    </div>
                                </div>
                                <div className="child_info-content_text_infobox ">
                                    <img
                                        src="/img/icon/krovat.svg"
                                        alt="Время размещения"
                                    />
                                    <div className="">
                                        <p className="t16">
                                            Вместимость — до{" "}
                                            {
                                                elem.capacity
                                                    .total
                                            }{" "}
                                            человек
                                        </p>
                                        {/* {elem.capacity
                                            .sleeping_places !==
                                            null && (
                                            <p className="t16">
                                                Спальных
                                                мест —{" "}
                                                {
                                                    elem
                                                        .capacity
                                                        .sleeping_places
                                                        .min
                                                }
                                                -
                                                {
                                                    elem
                                                        .capacity
                                                        .sleeping_places
                                                        .max
                                                }{" "}
                                                человек
                                            </p>
                                        )} */}
                                    </div>
                                </div>
                                <div className="child_info-content_text_infobox ">
                                    <img
                                        src="/img/icon/rub.svg"
                                        alt="Время размещения"
                                    />
                                    <div className="">
                                        <p className="t16">
                                            Будни —{" "}
                                            {
                                                elem.pricing
                                                    .weekdays
                                            }
                                        </p>
                                        <p className="t16">
                                            Выходные —{" "}
                                            {
                                                elem.pricing
                                                    .weekends
                                            }{" "}
                                        </p>
                                    </div>
                                </div>
                                <p className="t16">
                                    {elem.additional_services.map(
                                        (e, i) => (
                                                <span
                                                    key={i}
                                                >
                                                    {" "}
                                                    {
                                                        e.description
                                                    }{" "}
                                                    <br />
                                                </span>
                                        )
                                    )}
                                </p>
                            </div>
                            <div className="child_info-content_menu flex">
                                <button
                                    onClick={() =>
                                        hashPopUp("Запрос на бронирование: " + elem.typeName +" - " + elem.name)
                                    }
                                    className="button"
                                >
                                    ЗАБРОНИРОВАТЬ
                                </button>
                            </div>
                        </div>
                    </div>
                </Container>
                <Specifications arrSoec={elem.advantages} />
            </section>
            <ModalForm
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
            {elem.galary !== null && (
                <>
                <section className="sect-us">
                    <Container className="grid -g4">
                        {elem.galary.map((e, i) => (
                            <div
                            onClick={()=>handlePopForm(i)}
                                key={i}
                                className={`el-set ${
                                    i === 0
                                        ? "-column-2"
                                        : ""
                                }`}
                            >
                                <img src={e} alt="" />
                            </div>
                        ))}
                    </Container>
                </section>
            <PopFoto activeIndex={index} show={foto} setShow={setFoto} array={elem.galary}/></>

            )}

        </main>
    );
}
