import { useEffect } from "react";
import { useLocation } from "react-router";

const ScrollToTop = (props) => {
    const location = useLocation();
    // console.log(location);
    
    useEffect(() => {
        const hash = location.hash;
        if (hash) {
            // Прокручиваем до якоря, если он есть
            const element = document.querySelector(hash);
            if (element) {
                element.scrollIntoView({
                    behavior: "smooth",
                });
            }
        } else {
            // Проверяем, была ли страница перезагружена
            if (!sessionStorage.getItem("wasReloaded")) {
                sessionStorage.setItem(
                    "wasReloaded",
                    "true"
                );
                window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                });
            } else {
                sessionStorage.removeItem("wasReloaded");
            }

        }
        // console.log(sessionStorage);
        
    }, [location]);

    return <>{props.children}</>;
};

export default ScrollToTop;
