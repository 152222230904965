import { Carousel, Container } from "react-bootstrap";
import "./css/style.css";
import { NavLink } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";

export default function Hellow(params) {
    const [indexGo, setIndexGo] = useState(0);
    const [slide, setSlide] = useState(null);
    const handleSelect = (selectedIndex, e) => {
        setIndexGo(selectedIndex);
    };
    useEffect(() => {
        if (slide === null) {
            axios
                .get("/data/slider.json")
                .then((response) => {
                    setSlide(response.data);
                })
                .catch((error) => {
                    console.error(
                        "Error fetching the JSON data:",
                        error
                    );
                });
        }
    }, [slide]);

    return (
        <section id="hellow" className="hellow">
            <Container className="container-sm">
                <Carousel
                    // activeIndex={indexGo}
                    // onSelect={handleSelect}
                    controls={false}
                    indicators={false}
                    interval={10000}
                    className="hellow_slide"
                >
                    {slide !== null ? (
                        slide.map((e, i) => (
                            <Carousel.Item key={i} className="hellow_slide_item">
                                <div
                                    className="hellow_slide_img"
                                    style={{
                                        background: `url(${window.innerWidth >= 750 ? e.img : e.imgMob !==null ? e.imgMob :e.img})`,
                                    }}
                                ></div>
                                <div className="hellow_slide_content flex">
                                    <p
                                        dangerouslySetInnerHTML={{
                                            __html: e.text1,
                                        }}
                                        className="h3"
                                    ></p>
                                    <h1
                                        dangerouslySetInnerHTML={{
                                            __html: e.title,
                                        }}
                                        className="h1"
                                    ></h1>
                                    <p
                                        dangerouslySetInnerHTML={{
                                            __html: e.text2,
                                        }}
                                        className="h3"
                                    ></p>
                                </div>
                            </Carousel.Item>
                        ))
                    ) : (
                        <Carousel.Item className="hellow_slide_item">
                            <div
                                className="hellow_slide_img"
                                style={{
                                    background: `url(./img/hellow/slide/1.jpg)`,
                                }}
                            ></div>
                            <div className="hellow_slide_content flex">
                                <h1 className="h1">
                                    Комфортабельный отдых на
                                    свежем воздухе
                                </h1>
                            </div>
                        </Carousel.Item>
                    )}
                </Carousel>
                <NavLink
                    data-aos="fade-down"
                    data-aos-delay="200"
                    data-aos-once
                    to={"/#habitation"}
                    className="button flex hellow_cirle"
                >
                    выбрать <br /> дом
                </NavLink>
                <a
                    data-aos="fade-right"
                    data-aos-delay="200"
                    data-aos-once
                    href="https://yandex.ru/maps/195/ulyanovsk/?ll=48.533491%2C54.361813&mode=poi&poi%5Bpoint%5D=48.524738%2C54.367372&poi%5Buri%5D=ymapsbm1%3A%2F%2Forg%3Foid%3D1579447149&source=serp_navig&z=15"
                    target="_blank"
                    className="hellow_gomap_yan flex "
                >
                    <span className="t26">
                        яндекс карта
                    </span>
                </a>
                <NavLink
                    data-aos="fade-left"
                    data-aos-delay="200"
                    data-aos-once
                    to={"/interact_carta"}
                    className="button flex hellow_gomap_interact "
                >
                    <span className="t26">
                        карта турбазы
                    </span>
                </NavLink>
            </Container>
        </section>
    );
}
