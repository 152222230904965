import { RouterProvider, createBrowserRouter } from "react-router-dom";
import Main from "../page/main";
import Header from "../component/header";
import { AppProvider, useAppContext } from './Context'; // Импортируем провайдер и хук
import Footer from "../component/footer";
import Restaurant from "../page/restaurant";
import ScrollToTop from "../modules/scrolToTop";
import RentList from "../page/rentList";
import RentCertain from "../page/rentCertain";
import HouseComplex from "../page/houseComplex";
import InteraktivMap from "../page/interaktivMap";

function Basa({ children }) {
    const { someState, setSomeState } = useAppContext(); // Используем контекст

    return (
            <ScrollToTop>
            <Header />
            {/* Передаем someState и setSomeState в дочерние компоненты через контекст */}
            { children }
            <Footer/>
            </ScrollToTop>
    );
}

export function AppRouste() {
    const router = createBrowserRouter([
        {
            path: "/",
            element: (
                <Basa>
                    <Main />
                </Basa>
            ),
        },
        {
            path: "/restaurant",
            element:(
                <Basa>
                    <Restaurant/>
                </Basa>
            )
        },
        {
            path: "/rent/:categor",
            element:(
                <Basa>
                    <RentList/>
                </Basa>
            )
        },
        {
            path: "/rent/:categor/:type/:id",
            element:(
                <Basa>
                    <RentCertain/>
                </Basa>
            )
        },
        {
            path: "/rent/house_complex",
            element:(
                <Basa>
                    <HouseComplex/>
                </Basa>
            )
        },
        {
            path: "/interact_carta",
            element:(
                <Basa>
                    <InteraktivMap/>
                </Basa>
            )
        },
    ]);

    return (
        <AppProvider>
            <RouterProvider router={router} />
        </AppProvider>
    );
}
