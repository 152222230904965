import { Carousel, Container } from "react-bootstrap";
import "./css/style.css";
import { NavLink } from "react-router-dom";
import Title_block from "../title_block";
import FollowMouseBlock from "../../modules/FollowMouseBlock/FollowMouseBlock";

export default function Banquet(params) {
    return (
        <section id="banquet" className="banquet sect-basa">
            <Container className="container-sm">
                <Title_block
                    title={
                        <>
                            {" "}
                            Насладись отличной <br /> кухней
                        </>
                    }
                    type={"Торжества"}
                    discription={
                        <p className="t20">
                            Мы позаботились о том, чтобы
                            каждому гостю было
                            <br /> комфортно, продумав все
                            до мелочей!
                        </p>
                    }
                />

                <div className="banquet_content">
                    <FollowMouseBlock
                        to={"/restaurant"}
                        className="banquet_content_item i-1 flex"
                        followmouse={true}
                    >
                        <div className="i-1_box">
                            <h3 className="h2">
                                к вашим услугам <br />{" "}
                                уютный ресторан
                            </h3>
                            <p className="t28">
                                Вместительностью до 100
                                человек
                            </p>
                        </div>
                    </FollowMouseBlock>

                    <NavLink
                        data-aos="fade-up-right"
                        data-aos-delay="200"
                        data-aos-once
                        to={"/restaurant/#svatba"}
                        className="banquet_content_item i-2 hover_in"
                    >
                        <h3 className="h3">свадьбы</h3>
                        <div className="hover_in_img">
                            <img
                                src="/img/banquet/swadb.webp"
                                alt="свадьбы"
                            />
                        </div>
                    </NavLink>
                    <NavLink
                        data-aos="fade-up-right"
                        data-aos-delay="500"
                        data-aos-once
                        to={"/restaurant/#banqet"}
                        className="banquet_content_item i-3 hover_in"
                    >
                        <h3 className="h3">банкеты</h3>
                        <div className="hover_in_img">
                            <img
                                src="/img/banquet/banket.webp"
                                alt="банкеты"
                            />
                        </div>
                    </NavLink>
                    <NavLink
                        data-aos="fade-up-right"
                        data-aos-delay="700"
                        data-aos-once
                        to={"/restaurant/#company"}
                        className="banquet_content_item i-4 hover_in"
                    >
                        <h3 className="h3"> корпоративы</h3>
                        <div className="hover_in_img">
                            <img
                                src="/img/banquet/korp.webp"
                                alt="корпоративы"
                            />
                        </div>
                    </NavLink>
                </div>
            </Container>
        </section>
    );
}
