import { Carousel, Container } from "react-bootstrap";
import "./css/style.css";
import Title_block from "../title_block";
import { NavLink } from "react-router-dom";
import FollowMouseBlock from "../../modules/FollowMouseBlock/FollowMouseBlock";

export default function Habitation(params) {

    return (
        <section
            id="habitation"
            className="habitation sect-basa"
        >
            <Container className="container-sm">
                <Title_block
                    title={
                        <>
                            Проведи выходные наедине с
                            природой
                        </>
                    }
                    type={"Аренда"}
                />

                <div className="habitation_content">
                    <FollowMouseBlock
                        followmouse={true}
                        className="habitation_content_item i-1 hover_from"
                        to={"/rent/house_complex"}
                    >
                        <div>
                            <h3 className="h3">
                                комплекс хаус
                            </h3>
                            <div className="hover_from_img">
                                <img
                                    src="/img/habitation/haus.webp"
                                    alt="беседки"
                                />
                            </div>
                        </div>
                    </FollowMouseBlock>

                    <NavLink
                     data-aos="fade-down"
                     data-aos-delay="200"
                     data-aos-once
                        to={`/rent/gazebos`}
                        data-count="10"
                        className="habitation_content_item i-2 counts hover_in"
                    >
                        <h3 className="h3">беседки</h3>
                        <div className="hover_in_img">
                            <img
                                src="/img/habitation/besed.webp"
                                alt="беседки"
                            />
                        </div>
                    </NavLink>
                    <NavLink
                   data-aos="fade-down"
                   data-aos-delay="220"
                   data-aos-once
                        to={`/rent/bathsSaunas`}
                        data-count="1+1"
                        className="habitation_content_item i-3 counts hover_in"
                    >
                        <h3 className="h3">баня и сауна</h3>
                        <div className="hover_in_img">
                            <img
                                src="/img/habitation/banSauns.webp"
                                alt="беседки"
                            />
                        </div>
                    </NavLink>
                    <NavLink
                    data-aos="fade-down"
                    data-aos-delay="230"
                    data-aos-once
                        to={`rent/verandas/`}
                        data-count="5"
                        className="habitation_content_item i-4 counts hover_in"
                    >
                        <h3 className="h3">веранды</h3>
                        <div className="hover_in_img">
                            <img
                                src="/img/habitation/verand.webp"
                                alt="беседки"
                            />
                        </div>
                    </NavLink>

                    <FollowMouseBlock
                      
                        className="habitation_content_item i-5 hover_from"
                        followmouse={true}
                        to={`/rent/guest_home/`}
                    >
                        <div >
                            <h3 className="h3">
                                Гостевые дома
                            </h3>
                            <div className="hover_from_img">
                                <img
                                    src="/img/habitation/guest.webp"
                                    alt="беседки"
                                />
                            </div>
                        </div>
                    </FollowMouseBlock>
                </div>
            </Container>
        </section>
    );
}
