import { Accordion, Breadcrumb, Container } from "react-bootstrap";
import "./css/style.css";
import Title_block from "../../component/title_block";
import { useEffect, useState } from "react";
import { Specifications } from "../../modules/specifications";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { useAppContext } from "../../route/Context";
import ModalForm from "../../modules/popup";
import PopSlider from "../../modules/sliderPop";
import PopFoto from "../../modules/popFoto";

let elem = {
    galary: [
        "/img/rent/houseComplex/gallary/1.jpg",
        "/img/rent/houseComplex/gallary/2.jpg",
        "/img/rent/houseComplex/gallary/3.jpg",
        "/img/rent/houseComplex/gallary/4.jpg",
        "/img/rent/houseComplex/gallary/5.jpg",
        "/img/rent/houseComplex/gallary/6.jpg",
        "/img/rent/houseComplex/gallary/7.jpg",
        "/img/rent/houseComplex/gallary/8.jpg",
        "/img/rent/houseComplex/gallary/9.jpg",
        "/img/rent/houseComplex/gallary/10.jpg",
        "/img/rent/houseComplex/gallary/11.jpg",
        "/img/rent/houseComplex/gallary/12.jpg",
        "/img/rent/houseComplex/gallary/13.jpg",
        "/img/rent/houseComplex/gallary/14.jpg",
        "/img/rent/houseComplex/gallary/15.jpg",
        "/img/rent/houseComplex/gallary/16.jpg",
        "/img/rent/houseComplex/gallary/17.jpg",
        "/img/rent/houseComplex/gallary/18.jpg",
        "/img/rent/houseComplex/gallary/19.jpg",
        "/img/rent/houseComplex/gallary/20.jpg",
        "/img/rent/houseComplex/gallary/21.jpg",
        "/img/rent/houseComplex/gallary/22.jpg",
        "/img/rent/houseComplex/gallary/23.jpg",
        "/img/rent/houseComplex/gallary/24.jpg",
        "/img/rent/houseComplex/gallary/25.jpg",
        "/img/rent/houseComplex/gallary/26.jpg",
        "/img/rent/houseComplex/gallary/27.jpg",
        "/img/rent/houseComplex/gallary/28.jpg",
        "/img/rent/houseComplex/gallary/29.jpg",
        "/img/rent/houseComplex/gallary/30.jpg",
    ],
};

export default function HouseComplex() {
    const location = useLocation();
    const { setFormTheme, modalShow, setModalShow } = useAppContext(); // Используем контекст

    const [menuOpen, setMenuOpen] = useState(false);
    const [foto, setFoto] = useState(false);
    const [index, setindex] = useState(0);

    const hashPopUp = (theme) => {
        setFormTheme(theme);
        setModalShow(true);
    };
    const handlePopForm = (index) => {
        setFoto(true);
        setindex(index);
    };
    useEffect(() => {
        const url = window.location.href;

        // Обновляем существующие метатеги
        const metaDescription = document.querySelector('meta[name="description"]');
        const metaOGTitle = document.querySelector('meta[property="og:title"]');
        const metaOGDescription = document.querySelector('meta[property="og:description"]');
        const metaOGUrl = document.querySelector('meta[property="og:url"]');
        const metaOGIMG = document.querySelector('meta[property="og:image"]');

        if (metaDescription)
            metaDescription.content =
                "   Большой двухэтажный дом с верандой на 12–ть мест + 6 дополнительных мест (6 спален и большая гостиная) с теплым полом";
        if (metaOGTitle) metaOGTitle.content = 'Аренда - Комплекс Хаус | Турбаза в Ульяновске "Иволга"';
        if (metaOGDescription)
            metaOGDescription.content =
                "   Большой двухэтажный дом с верандой на 12–ть мест + 6 дополнительных мест (6 спален и большая гостиная) с теплым полом";
        if (metaOGUrl) metaOGUrl.content = url;
        if (metaOGIMG) metaOGIMG.content = `https://xn--73-6kchf2awx.xn--p1ai/img/rent/houseComplex/prev.jpg`;

        // Обновляем заголовок страницы
        document.title = 'Аренда - Комплекс Хаус | Турбаза в Ульяновске "Иволга"';
    }, []);
    return (
        <main className="houseComplex">
            <Container className="container-sm">
                <Breadcrumb className="breadcrumbs">
                    <Breadcrumb.Item href="/">Главная</Breadcrumb.Item>
                    <Breadcrumb.Item href="/#habitation">Аренда</Breadcrumb.Item>
                    <Breadcrumb.Item active>Комплекс Хаус</Breadcrumb.Item>
                </Breadcrumb>
            </Container>

            <section className="sect-us">
                <Container className="container-sm ">
                    <Title_block title={"Комплекс Хаус"} type={"Услуги"} />

                    <div className="restaurant_info child_info">
                        <div className="child_info-img">
                            <img src="/img/rent/houseComplex/prev.jpg" alt={" на территори иволги"} />
                        </div>

                        <div className="child_info-content">
                            <div className="child_info-content_text">
                                <p className="t16">
                                    Большой двухэтажный дом с верандой на 12–ть мест + 6 дополнительных мест (6 спален и большая гостиная) с теплым
                                    полом
                                </p>
                                <Accordion className="child_info_acar" defaultActiveKey="0">
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header className="t16">1 этаж:</Accordion.Header>
                                        <Accordion.Body>
                                            <ul>
                                                <li>Просторная гостиная с большим камином на дровах и панорамными окнами</li>
                                                <li>1 спальня с двуспальной кроватью, панорамным окном, шкафом-купе, телевизором и зеркалом</li>
                                                <li>Сауна с кедровой бочкой, душевой кабиной и зоной отдыха</li>
                                                <li>Ванная комната с душевой кабиной и биде</li>
                                            </ul>
                                            <ul>
                                                <p className="t16">Гостиная:</p>
                                                <li>Большой камин на дровах</li>
                                                <li>2 двуспальных дивана</li>
                                                <li>Журнальный столик</li>
                                                <li>Большой телевизор</li>
                                            </ul>
                                            <ul>
                                                <p className="t16">Обеденный стол на 12- 16 персон:</p>
                                                <li>Большой холодильник</li>
                                                <li>Электроплита</li>
                                                <li>Микроволновая печь</li>
                                                <li>Чайник</li>
                                                <li>Кухонные принадлежности и посуда</li>
                                                <li>Чистящие и моющие средства</li>
                                            </ul>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="1">
                                        <Accordion.Header>2 этаж:</Accordion.Header>
                                        <Accordion.Body>
                                            <ul>
                                                <li>5 спален с двуспальной кроватью, панорамными окнами, телевизором, шкафом купе и зеркалом</li>
                                                <li>Ванная комната с душевой кабиной и биде</li>
                                                <li>Двуспальный диван и журнальный столик в гостиной</li>
                                            </ul>

                                            <ul>
                                                <p className="t16">Прихожая:</p>
                                                <li>Тёплый холл</li>
                                                <li>Зеркало</li>
                                                <li>Пуф и шкаф для обуви</li>
                                            </ul>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>

                                <p className="t16">
                                    В стоимость проживания в апартаментах входят завтраки. Поверьте, ничего нет приятнее, чем сидеть у камина с
                                    потрескивающими поленьями… <br />
                                    Теплая беседка с зоной барбекю <br />В теплой беседке комфортно в любую погоду… И зимой и летом, и в дождь и
                                    ветер. Внутри есть очаг, столешница с раковиной с проточной водой, круглый стол, стулья, решетки, вытяжной купол,
                                    дрова, розжиг, уголь и казан.
                                </p>

                                <div className="child_info-content_text_infobox ">
                                    <img src="/img/icon/krovat.svg" alt="Время размещения" />
                                    <div className="">
                                        <p className="t16">Время заезда: в 16:00</p>
                                        <p className="t16">Время выезда: в 14:00</p>
                                        <a href="http://" className="link" target="_blank" rel="noopener noreferrer">
                                            правила проживания ↗
                                        </a>
                                    </div>
                                </div>

                                <div className="child_info-content_text_infobox ">
                                    <img src="/img/icon/krovat.svg" alt="Время размещения" />
                                    <div className="">
                                        <p className="t16">Вместимость — на 50 человек</p>
                                    </div>
                                </div>

                                <div className="child_info-content_text_infobox ">
                                    <img src="/img/icon/rub.svg" alt="Время размещения" />
                                    <div className="">
                                        <p className="t16">Будни — 60 000₽</p>
                                        <p className="t16">Выходные — 60 000₽</p>
                                    </div>
                                </div>
                            </div>
                            <div className="child_info-content_menu flex">
                                <button onClick={() => hashPopUp("Забранировать Комплекс Хаус ")} className="button">
                                    ЗАБРОНИРОВАТЬ
                                </button>
                            </div>
                        </div>
                    </div>
                </Container>
            </section>
            <Specifications
                arrSoec={[
                    {
                        nameKat: "Оснащение",
                        list: ["7 комнат", "6 спален", "Верхняя", "Гостиная", "Освещение", "Прихожая"],
                    },
                    {
                        nameKat: "Санузел",
                        list: ["2 ванные комнаты", "2 санузла", "Биде"],
                    },
                    {
                        nameKat: "Удобства",
                        list: ["Камин на дровах", "Сауна с кедровой бочкой", "Общественная зона"],
                    },
                    {
                        nameKat: "Развлечения",
                        list: ["Бесплатный доступ WI-FI", "Мангальная зона", "Двухъярусная беседка", "Теплая беседка с зоной барбекю"],
                    },
                ]}
            />

            <ModalForm show={modalShow} onHide={() => setModalShow(false)} />
            <section className="sect-us">
                <Container className="grid -g4">
                    <div onClick={() => handlePopForm(0)} className="el-set -column-2">
                        <img src="/img/rent/houseComplex/gallary/1.jpg" alt="" />
                    </div>
                    <div onClick={() => handlePopForm(1)} className="el-set">
                        <img src="/img/rent/houseComplex/gallary/2.jpg" alt="" />
                    </div>
                    <div onClick={() => handlePopForm(2)} className="el-set">
                        <img src="/img/rent/houseComplex/gallary/3.jpg" alt="" />
                    </div>
                    <div onClick={() => handlePopForm(3)} className="el-set">
                        <img src="/img/rent/houseComplex/gallary/4.jpg" alt="" />
                    </div>
                    <div onClick={() => handlePopForm(4)} className="el-set">
                        <img src="/img/rent/houseComplex/gallary/5.jpg" alt="" />
                    </div>
                    <div onClick={() => handlePopForm(5)} className="el-set">
                        <img src="/img/rent/houseComplex/gallary/6.jpg" alt="" />
                    </div>
                    <div onClick={() => handlePopForm(6)} className="el-set">
                        <img src="/img/rent/houseComplex/gallary/7.jpg" alt="" />
                    </div>
                    <div onClick={() => handlePopForm(7)} className="el-set">
                        <img src="/img/rent/houseComplex/gallary/8.jpg" alt="" />
                    </div>
                    <div onClick={() => handlePopForm(8)} className="el-set">
                        <img src="/img/rent/houseComplex/gallary/9.jpg" alt="" />
                    </div>
                    <div onClick={() => handlePopForm(9)} className="el-set -column-2">
                        <img src="/img/rent/houseComplex/gallary/10.jpg" alt="" />
                    </div>
                    <div onClick={() => handlePopForm(10)} className="el-set">
                        <img src="/img/rent/houseComplex/gallary/11.jpg" alt="" />
                    </div>
                    <div onClick={() => handlePopForm(11)} className="el-set">
                        <img src="/img/rent/houseComplex/gallary/12.jpg" alt="" />
                    </div>
                    <div onClick={() => handlePopForm(12)} className="el-set">
                        <img src="/img/rent/houseComplex/gallary/13.jpg" alt="" />
                    </div>
                    <div onClick={() => handlePopForm(13)} className="el-set">
                        <img src="/img/rent/houseComplex/gallary/14.jpg" alt="" />
                    </div>
                    <div onClick={() => handlePopForm(14)} className="el-set">
                        <img src="/img/rent/houseComplex/gallary/15.jpg" alt="" />
                    </div>
                    <div onClick={() => handlePopForm(15)} className="el-set">
                        <img src="/img/rent/houseComplex/gallary/16.jpg" alt="" />
                    </div>
                    <div onClick={() => handlePopForm(16)} className="el-set">
                        <img src="/img/rent/houseComplex/gallary/17.jpg" alt="" />
                    </div>
                    <div onClick={() => handlePopForm(17)} className="el-set">
                        <img src="/img/rent/houseComplex/gallary/18.jpg" alt="" />
                    </div>
                    <div onClick={() => handlePopForm(18)} className="el-set">
                        <img src="/img/rent/houseComplex/gallary/19.jpg" alt="" />
                    </div>
                    <div onClick={() => handlePopForm(19)} className="el-set">
                        <img src="/img/rent/houseComplex/gallary/20.jpg" alt="" />
                    </div>
                    <div onClick={() => handlePopForm(20)} className="el-set">
                        <img src="/img/rent/houseComplex/gallary/21.jpg" alt="" />
                    </div>
                    <div onClick={() => handlePopForm(21)} className="el-set">
                        <img src="/img/rent/houseComplex/gallary/22.jpg" alt="" />
                    </div>
                    <div onClick={() => handlePopForm(22)} className="el-set">
                        <img src="/img/rent/houseComplex/gallary/23.jpg" alt="" />
                    </div>
                    <div onClick={() => handlePopForm(23)} className="el-set">
                        <img src="/img/rent/houseComplex/gallary/24.jpg" alt="" />
                    </div>
                    <div onClick={() => handlePopForm(24)} className="el-set">
                        <img src="/img/rent/houseComplex/gallary/25.jpg" alt="" />
                    </div>
                    <div onClick={() => handlePopForm(25)} className="el-set">
                        <img src="/img/rent/houseComplex/gallary/26.jpg" alt="" />
                    </div>
                    <div onClick={() => handlePopForm(26)} className="el-set">
                        <img src="/img/rent/houseComplex/gallary/27.jpg" alt="" />
                    </div>
                    <div onClick={() => handlePopForm(27)} className="el-set">
                        <img src="/img/rent/houseComplex/gallary/28.jpg" alt="" />
                    </div>
                    <div onClick={() => handlePopForm(28)} className="el-set">
                        <img src="/img/rent/houseComplex/gallary/29.jpg" alt="" />
                    </div>
                    <div onClick={() => handlePopForm(29)} className="el-set">
                        <img src="/img/rent/houseComplex/gallary/30.jpg" alt="" />
                    </div>
                </Container>
            </section>
            <PopFoto activeIndex={index} show={foto} setShow={setFoto} array={elem.galary} />
        </main>
    );
}
