import axios from "axios";

export const URL_DEFAULT ='/mail/mail.php';


// Функция запроса для выполнения запроса на вход
export async function MailGo(data) {
    console.log(data);
    try {
        const response = await axios({
            method: 'POST',
            url: URL_DEFAULT,
            headers: {
                'Content-Type': 'application/json',
            },
            data: JSON.stringify(data),
        });
        console.log(response.data.success);
        
        return response.data.success;

    } catch (error) {
        console.error(error);
        throw error; // Передаем ошибку дальше
    }
}