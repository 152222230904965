import React from 'react';
import ReactDOM from 'react-dom/client';
import 'react-tooltip/dist/react-tooltip.css';

import './css/glob.css';
import './fonts/fonts.css';
import { AppRouste } from './route';
import "aos/dist/aos.css";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <AppRouste/>
  </React.StrictMode>
);

