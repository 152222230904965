import { Container } from "react-bootstrap";
import "./css/style.css";

export function Specifications({ arrSoec }) {
    if (!arrSoec) {
        return <></>;
    }
    return (
        <div className="specifications sect-us">
            <Container className="flex">
                {arrSoec.map((e, key) => (
                    <div key={key + "spec"}>
                        <h3
                            data-aos="fade-down"
                            data-aos-delay={`10`}
                            data-aos-once
                            className="t18 specifications_title"
                        >
                            {e.nameKat}
                        </h3>
                        <nav className="specifications_list_box">
                            <ul>
                                {e.list.map(
                                    (item, index) => (
                                        <li
                                            data-aos="fade-down"
                                            data-aos-delay={`${index}00`}
                                            data-aos-once
                                            className="specifications_list_item t16"
                                            key={index}
                                        >
                                            {item}
                                        </li>
                                    )
                                )}
                            </ul>
                        </nav>
                    </div>
                ))}
            </Container>
        </div>
    );
}
