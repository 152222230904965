import { Carousel, Container } from "react-bootstrap";
import "./css/style.css";
import Title_block from "../title_block";
import Slider from "react-slick";
import { useEffect, useRef, useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FollowMouseBlockSkin } from "../../modules/FollowMouseBlock/FollowMouseBlock";
const video = [
    {
        url: "/video/4.webm",
        urlB: "/video/4.MOV",
    },
    {
        url: "/video/5.webm",
        urlB: "/video/5.MOV",
    },
    {
        url: "/video/6.webm",
        urlB: "/video/6.MOV",
    },
    {
        url: "/video/7.webm",
        urlB: "/video/7.MOV",
    },
    {
        url: "/video/8.webm",
        urlB: "/video/8.MOV",
    },
    {
        url: "/video/9.webm",
        urlB: "/video/9.MOV",
    },
];
export default function Aboutus(params) {
    const videoRefs = useRef([]);
    const [indexvideo, setindexvideo] = useState(0);
    const handleVideoPlay = (e, index) => {
        let a = e.target
        if (a.paused) {
            a.play();
        } else {
            a.pause();
       }
       
        // Ставим на паузу все видео
        document
            .querySelectorAll(".aboutus_slider_item_vidos")
            .forEach((videoI, i) => {
                if (i !== index && videoI !== a) {
                    videoI.pause();
                }
            });
        
        // Устанавливаем активный индекс
        setindexvideo(index);
       
            
 
 
    };
    const sliderRef = useRef(null);

    const goToNext = () => {
        sliderRef.current.slickNext();
    };

    const goToPrev = () => {
        sliderRef.current.slickPrev();
    };
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        draggable: true,
        responsive: [
            {
                breakpoint: 1400,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 1000,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };
    useEffect(() => {
  
    }, [indexvideo])
    
    return (
        <section id="aboutus" className="aboutus sect-basa">
            <Container className="container-sm">
                <Title_block
                    title={
                        <>
                            <span>
                                База отдыха “Иволга”
                            </span>{" "}
                            - это <br />
                            сочетание неповторимой <br />
                            красоты Волги <br />
                        </>
                    }
                    discription={
                        <p className="t16 flex">
                            <span>
                                Территория базы находится в
                                окружении соснового бора:
                                чистейший воздух, тишина,
                                прекрасные лесные пейзажи и
                                10 метров до берега реки. А
                                какой у нас вид на Волгу!
                            </span>
                            <span>
                                Этой красотой можно
                                любоваться бесконечно! И в
                                любое время года! Поэтому,
                                Иволга, является излюбленным
                                местом отдыха горожан и
                                гостей нашего города!
                            </span>
                        </p>
                    }
                    type={"О нас"}
                />
                <div className="aboutus_content">
                    <Slider
                        ref={sliderRef}
                        {...settings}
                        className="aboutus_slider"
                    >
                        {video.map((e, i) => (
                            <div
                                data-aos="fade-down"
                                data-aos-delay={`${
                                    i * 1
                                }00`}
                                data-aos-once
                                className="aboutus_slider_item"
                                key={i}
                            >
                                <video
                                    className="aboutus_slider_item_vidos"
                                    controls
                                    onClick={(e) => {
                                        e.preventDefault();
                                        handleVideoPlay(e, i);
                                    }}
                                >
                                    <source
                                        src={e.url}
                                        type="video/webm"
                                    />
                                    <source
                                        src={e.urlB}
                                        type="video/MOV"
                                    />
                                    Ваш браузер не
                                    поддерживает видео.
                                </video>
                            </div>
                        ))}
                    </Slider>
                    <div className="aboutus_slider_arrow">
                        <FollowMouseBlockSkin
                            onClick={goToPrev}
                            followmouse={true}
                            className="aboutus_slider_arrow_item left"
                        >
                            <div></div>
                        </FollowMouseBlockSkin>
                        <FollowMouseBlockSkin
                            onClick={goToNext}
                            followmouse={true}
                            className="aboutus_slider_arrow_item right"
                        >
                            <div></div>
                        </FollowMouseBlockSkin>
                    </div>
                </div>
            </Container>
        </section>
    );
}
