import {
    CloseButton,
    Container,
    Dropdown,
    DropdownButton,
    Nav,
    Navbar,
    NavDropdown,
} from "react-bootstrap";
import "./css/style.css";
import { NavLink } from "react-router-dom";
import { useState } from "react";

export default function Header(params) {
     const [show, setShow] = useState(false);
     const handleClose = () => setShow(false);
     const handleToggle = () => setShow(!show);
    return (
        <header id="header" className="header">
            <Navbar expand="xxl">
                <Container className="container-sm">
                    <Navbar.Brand
                        className="header_logo"
                        href="/"
                    >
                        <img src="/logo.png" alt="" />
                    </Navbar.Brand>
                    <Navbar.Toggle onClick={handleToggle}  aria-controls="basic-navbar-nav" />
                    <Navbar.Offcanvas
                      show={show} onHide={handleClose}
                        className="header_menu"
                        id="basic-navbar-nav"
                    >
                        <Nav className="header_nav flex">
                            <NavLink
                                className="header_link t18"
                                to="/#habitation"
                            >
                                Проживание
                            </NavLink>
                            <NavLink
                                className="header_link t18"
                                to="/#rest"
                            >
                                Отдых
                            </NavLink>
                            <NavLink
                                className="header_link t18"
                                to="/restaurant"
                            >
                                Ресторан
                            </NavLink>
                            <NavLink
                                className="header_link t18"
                                to="#link"
                            >
                                Карта
                            </NavLink>
                            <NavLink
                                className="header_link t18"
                                to="/#aboutus"
                            >
                                О нас
                            </NavLink>
                            <div className="header_contact_soc">
                                <a
                                    className="footer_sitemap_contact_tel"
                                    href="tel:+79372753515"
                                >
                                    +7 (937) 275-35-15
                                </a>
                                <a
                                    className="footer_sitemap_contact_tel"
                                    href="tel:+79272700212"
                                >
                                    +7 (927) 270-02-12
                                </a>
                                <a
                                    className="footer_sitemap_link_item"
                                    href=""
                                >
                                    г. Ульяновск, ул.
                                    Оренбургская, 42а
                                </a>
                                <Nav.Item className="footer_sitemap_contact_time">
                                    Время работы: <br />
                                    <p className="t16">
                                        Пн-Вс: с 09:00 д
                                        21:00
                                    </p>
                                </Nav.Item>
                                <Nav.Item className="flex footer_sitemap_contact_icon_box">
                                    <a
                                        target="_blanc"
                                        href="https://t.me/ivolga73"
                                        className="footer_sitemap_contact_icon flex"
                                    >
                                        <img
                                            src="/img/icon/tg.svg"
                                            alt=""
                                        />
                                    </a>
                                    <a
                                        target="_blank"
                                        href="https://vk.com/ul_ivolga73"
                                        className="footer_sitemap_contact_icon flex"
                                    >
                                        <img
                                            src="/img/icon/vp.svg"
                                            alt=""
                                        />
                                    </a>
                                </Nav.Item>
                            </div>
                            <CloseButton className="header_menu_close" onClick={handleToggle}/>
                        </Nav>
                    </Navbar.Offcanvas>
                    <Nav.Link
                        className="button flex header_tel t18"
                        href="tel:+79372753515"
                    >
                        <span className="t18">
                            +7 (937) 275-35-15
                        </span>
                    </Nav.Link>
                    <Nav.Link
                        className="button flex header_tel t18"
                        href="tel:+79272700212"
                    >
                        <span className="t18">
                            +7 (927) 270-02-12
                        </span>
                    </Nav.Link>
                    <DropdownButton
                        className="header_tel_mob"
                        drop={"start"}
                        variant="secondary"
                        title={
                            <img
                                src="img/icon/tel.svg"
                                alt=""
                            />
                        }
                    >
                        <Dropdown.Item  href="tel:+79372753515" eventKey="1">
                          
                                <span className="t18">
                                    +7 (937) 275-35-15
                                </span>
                           
                        </Dropdown.Item>
                        <Dropdown.Item   href="tel:+79272700212" eventKey="2">
                    
                                <span className="t18">
                                    +7 (927) 270-02-12
                                </span>
                        </Dropdown.Item>
                    </DropdownButton>
                </Container>
            </Navbar>
        </header>
    );
}
