import React, { createContext, useContext, useEffect, useState } from 'react';
import AOS from "aos";

// Создаем контекст
const AppContext = createContext();

// Создаем провайдер контекста
export const AppProvider = ({ children }) => {
    
    const [modalShow, setModalShow] = useState(false);
    const [formTheme, setFormTheme] = useState('');
    const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });


    useEffect(() => {
        AOS.init({
            duration: 1000, // Длительность анимации
        });
    }, []);
    return (
        <AppContext.Provider value={{ formTheme, setFormTheme, modalShow, setModalShow, mousePosition, setMousePosition }}>
            {children}
        </AppContext.Provider>
    );
};

// Создаем хук для использования контекста
export const useAppContext = () => {
    return useContext(AppContext);
};
