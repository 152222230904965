import React, { useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import { Breadcrumb, Container } from "react-bootstrap";
import axios from "axios";
import Title_block from "../../component/title_block";
import { motion, AnimatePresence } from "framer-motion";
import "./css/style.css";

export default function RentList() {
    const params = useParams();
    const [data, setData] = useState(null);
    const [types, setTypes] = useState("all");

    useEffect(() => {
        axios
            .get("/data/dataBase.json")
            .then((response) => {
                setData(response.data.rent[params.categor]);
            })
            .catch((error) => {
                console.error("Error fetching the JSON data:", error);
            });
    }, [params.categor]);

    if (!data) {
        return (
            <Container className="container-sm">
                Loading...
            </Container>
        );
    }

    const filteredData =
        types === "all"
            ? data.type.flatMap((type) => type.list)
            : data.type[types].list;

    return (
        <main className="rentList">
            <Container className="container-sm">
                <Breadcrumb className="breadcrumbs">
                    <Breadcrumb.Item href="/">
                        Главная
                    </Breadcrumb.Item>
                    <Breadcrumb.Item href="/#habitation">
                        Аренда
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>
                        {data.nameKat}
                    </Breadcrumb.Item>
                </Breadcrumb>
            </Container>

            <section className="rentList sect-us">
                <Container className="container-sm ">
                    <Title_block
                        title={data.nameKat}
                        type={"Услуги"}
                        discription={
                            data.type.length > 1 && !data.typeTogether && (
                                <div className="menu flex">
                                    <label
                                        htmlFor="type-all"
                                        className="menu_item t20"
                                        onClick={() => setTypes("all")}
                                    >
                                        <input
                                            type="radio"
                                            name="type"
                                            id="type-all"
                                            value={"all"}
                                            defaultChecked
                                        />
                                        Все
                                    </label>
                                    {data.type.map((e, i) => (
                                        <label
                                            key={i}
                                            htmlFor={`type-${i}`}
                                            className="menu_item t20"
                                            onClick={() => setTypes(i)}
                                        >
                                            <input
                                                type="radio"
                                                name="type"
                                                id={`type-${i}`}
                                                value={i}
                                            />
                                            {e.typeName}
                                        </label>
                                    ))}
                                </div>
                            )
                        }
                    />
                </Container>

                <div className="sect-us">
                    <Container className="container-sm grid -g3">
                        <AnimatePresence>
                            {filteredData.map((item, index) => (
                                <motion.div
                                    key={item.typeTitle + item.title}
                                    layout // Включаем анимацию макета
                                    initial={{ opacity: 0, height: 0 }}
                                    animate={{ opacity: 1, height: "auto" }}
                                    exit={{ opacity: 0, height: 0 }}
                                    transition={{
                                        opacity: { duration: 0.3 },

                                        layout: { duration: 0.5 },
                                        height: { duration: 0.5 } // Замедляем изменение высоты
                                    }}
                                >
                                    <NavLink
                                        to={`/rent/${params.categor}/${item.typeTitle}/${item.title}`}
                                        className="el-set flex"
                                    >
                                        <img
                                            className="el-set_img_prev"
                                            src={item.previevImg}
                                            alt=""
                                        />
                                        <p className="t16">
                                            от {item.pricing.weekdays}
                                        </p>
                                        <h3 className="h3">{item.name}</h3>
                                    </NavLink>
                                </motion.div>
                            ))}
                        </AnimatePresence>
                    </Container>
                </div>
            </section>
        </main>
    );
}
