
export default function Title_block({
    title,
    type,
    discription,
}) {
 
    return (
        <div
            data-aos="fade-down"
            data-aos-delay="200"
            data-aos-once
            className="title flex"
        >
            <div className="title_text flex">
                <h2 className="h2">{title}</h2>
                {discription}
            </div>

            <p  data-aos="fade-left"
                        data-aos-delay="500"
                        data-aos-once className="t20">{type}</p>
        </div>
    );
}
