import React, { useEffect, useRef } from 'react';
import './css/style.css'; // Импортируйте ваш CSS файл
import { NavLink } from 'react-router-dom';


const FollowMouseBlock = ({ children, followmouse=false, className, to }) => {
    const blockRef = useRef(null);

    useEffect(() => {
        const handleMouseMove = (event) => {
            if (blockRef.current) {
                const rect = blockRef.current.getBoundingClientRect();
                const x = event.clientX - rect.left - 15; // Центрируем по X
                const y = event.clientY - rect.top - 15; // Центрируем по Y
                blockRef.current.style.setProperty('--mouse-x', `${x}px`);
                blockRef.current.style.setProperty('--mouse-y', `${y}px`);
            }
        };

        const handleMouseEnter = () => {
            if (blockRef.current) {
                blockRef.current.classList.add('hovered');
                blockRef.current.style.cursor = 'none'; // Скрываем курсор
            }
        };

        const handleMouseLeave = () => {
            if (blockRef.current) {
                blockRef.current.classList.remove('hovered');
                blockRef.current.style.cursor = 'default'; // Возвращаем курсор
            }
        };

        if (followmouse && blockRef.current) {
            blockRef.current.addEventListener('mousemove', handleMouseMove);
            blockRef.current.addEventListener('mouseenter', handleMouseEnter);
            blockRef.current.addEventListener('mouseleave', handleMouseLeave);
        }

        return () => {
            if (followmouse && blockRef.current) {
                blockRef.current.removeEventListener('mousemove', handleMouseMove);
                blockRef.current.removeEventListener('mouseenter', handleMouseEnter);
                blockRef.current.removeEventListener('mouseleave', handleMouseLeave);
            }
        };
    }, [followmouse]);

    return (
        <NavLink data-aos="fade-down"
        data-aos-delay="200"
        data-aos-once to={to} ref={blockRef} className={`block ${className || ''}`}>
            {children}
        </NavLink>
    );
};


export const FollowMouseBlockSkin = (props) => {
    const blockRef = useRef(null);

    useEffect(() => {
        const handleMouseMove = (event) => {
            if (blockRef.current) {
                const rect = blockRef.current.getBoundingClientRect();
                const x = event.clientX - rect.left - 15; // Центрируем по X
                const y = event.clientY - rect.top - 15; // Центрируем по Y
                blockRef.current.style.setProperty('--mouse-x', `${x}px`);
                blockRef.current.style.setProperty('--mouse-y', `${y}px`);
            }
        };

        const handleMouseEnter = () => {
            if (blockRef.current) {
                blockRef.current.classList.add('hovered');
                blockRef.current.style.cursor = 'none'; // Скрываем курсор
            }
        };

        const handleMouseLeave = () => {
            if (blockRef.current) {
                blockRef.current.classList.remove('hovered');
                blockRef.current.style.cursor = 'default'; // Возвращаем курсор
            }
        };

        if (props.followmouse && blockRef.current) {
            blockRef.current.addEventListener('mousemove', handleMouseMove);
            blockRef.current.addEventListener('mouseenter', handleMouseEnter);
            blockRef.current.addEventListener('mouseleave', handleMouseLeave);
        }

        return () => {
            if (props.followmouse && blockRef.current) {
                blockRef.current.removeEventListener('mousemove', handleMouseMove);
                blockRef.current.removeEventListener('mouseenter', handleMouseEnter);
                blockRef.current.removeEventListener('mouseleave', handleMouseLeave);
            }
        };
    }, [props.followmouse]);

    return (
        <div {...props}  ref={blockRef} className={`block ${props.className || ''}`}>
            {props.children}
        </div>
    );
};
export default FollowMouseBlock;
