import { useEffect, useRef, useState } from "react";
import { Carousel, Modal } from "react-bootstrap";
import Slider from "react-slick";
import "./css/style.css";
export default function PopFoto({activeIndex=0, show, setShow, array }) {
    const sliderRef = useRef(null);
    const [index, setIndex] = useState(activeIndex)
    const goToNext = () => {
        sliderRef.current.slickNext();
    };

    const goToPrev = () => {
        sliderRef.current.slickPrev();
    };
    console.log(array);
    useEffect(() => {
        setIndex(activeIndex)
    }, [activeIndex])
    const handleSelect = (selectedIndex) => {
        setIndex(selectedIndex);
      };
    return (
        <Modal
            size="xl"
            className="popFoto"
            show={show}
            onHide={() => setShow(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
        >
            <Modal.Body>
                <Carousel activeIndex={index} onSelect={handleSelect}>
                    {array && array.map((e, i) => (
                        <Carousel.Item
                            className="popFoto_item"
                            key={i}
                        >
                            <img src={e} alt="" />
                        </Carousel.Item>
                    ))}
                </Carousel>
            </Modal.Body>
        </Modal>
    );
}
