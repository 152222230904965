import { Carousel, Container } from "react-bootstrap";
import "./css/style.css";
import Title_block from "../title_block";

export default function Rest(params) {
    return (
        <section id="rest" className="rest sect-basa">
            <Container className="container-sm">
                <Title_block
                    title={<>Проведи отдых <br /> комфортно</>}
                    type={"Отдых"}
                />

                <div className="rest_content">
                    <div   data-aos="fade-down"
                        data-aos-delay="200"
                        data-aos-once className="rest_content_item i-1">
                        <div className="rest_content_item_text">
                            <h3 className="h3">
                                детские площадки
                            </h3>
                            <p className="t16">
                                На территории базы имеются
                                четыре детские игровые <br />{" "}
                                площадки
                            </p>
                        </div>
                    </div>
                    <div data-aos="fade-down"
                        data-aos-delay="200"
                        data-aos-once className="rest_content_item i-2">
                        <div className="rest_content_item_text">
                            <h3 className="h3">
                                игровая комната
                            </h3>
                            <p className="t16">
                                Для ваших детей организована
                                детская игровая комната.
                            </p>{" "}
                        </div>
                    </div>
                    <div  data-aos="fade-down"
                        data-aos-delay="200"
                        data-aos-once className="rest_content_item i-3">
                        <div className="rest_content_item_text">
                            <h3 className="h3">
                                прокат оборудования
                            </h3>
                            <p className="t16">
                                На базе отдыха Иволга
                                действует прокат велосипедов
                                <br /> и квадрациклов
                            </p>{" "}
                        </div>
                    </div>
                    <div  data-aos="fade-down"
                        data-aos-delay="200"
                        data-aos-once className="rest_content_item i-4">
                        <div className="rest_content_item_text">
                            <h3 className="h3">
                                открытый  бассейн
                            </h3>
                            <p className="t16">
                                Летний уличный бассейн
                                вместительностью <br /> до
                                20 человек
                            </p>{" "}
                        </div>
                    </div>
                </div>
            </Container>
        </section>
    );
}
