import { Modal } from "react-bootstrap";
import Slider from "react-slick";
import "./css/style.css"
export default function PopSlider({ onHide, show, item=null }) {
    const settings = {
        infinite: true,
        speed: 700,
        slidesToShow: 1,
        slidesToScroll: 1,
        cssEase: 'cubic-bezier(0.455, 0.03, 0.515, 0.955)',
        customPaging: i => (
          <div className="custom-dot">{i + 1}</div>
        ),
      };
    
    return (
        <Modal
            show={show}
            onHide={onHide}
            className="popSlider"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
                {item !== null && (
                    <Slider className="popSlider_body" {...settings}>
                        {item.map((e, i) => (
                            <div
                                key={i}
                                className="popSlider_item"
                            >
                                <img
                                    src={e}
                                    alt="Меню рестора на территории иволги"
                                />
                            </div>
                        ))}
                    </Slider>
                )}
        </Modal>
    );
}
