import Aboutus from "../../component/aboutus";
import Banquet from "../../component/banquet";
import Habitation from "../../component/habitation";
import Hellow from "../../component/hellow";
import Rest from "../../component/rest";

export default function Main(params) {
    

    return (
        <main>
            <Hellow/>
            <Habitation/>
            <Banquet/>
            <Rest/>
            <Aboutus/>
        </main>
    )
}