import {
    Container,
    Nav,
    Navbar,
    NavDropdown,
} from "react-bootstrap";
import "./css/style.css";
import { NavLink } from "react-router-dom";

export default function Footer(params) {
    return (
        <footer id="footer" className="footer">
            <Container className="container-sm">
                <NavLink className="footer_logo" to="/">
                    <p className="h1">ИВОЛГА</p>
                    <p className="t16">БАЗА ОТДЫХА</p>
                </NavLink>
                <div className="footer_sitemap">
                    <Nav className="footer_sitemap_link">
                        <NavLink
                            className={
                                "footer_sitemap_link_item"
                            }
                            to={"/#habitation"}
                        >
                            Проживание
                        </NavLink>
                        <NavLink
                            className={
                                "footer_sitemap_link_item"
                            }
                            to={""}
                        >
                            Карта
                        </NavLink>
                        <NavLink
                            className={
                                "footer_sitemap_link_item"
                            }
                            to={"/restaurant"}
                        >
                            Ресторан
                        </NavLink>
                        <NavLink
                            className={
                                "footer_sitemap_link_item"
                            }
                            to={"/#rest"}
                        >
                            Отдых
                        </NavLink>
                        <NavLink
                            className={
                                "footer_sitemap_link_item"
                            }
                            to={"/#aboutus"}
                        >
                            О нас
                        </NavLink>
                    </Nav>

                    <Nav className="footer_sitemap_contact">
                        <a
                            className="footer_sitemap_contact_tel"
                            href="tel:+79372753515"
                        >
                            +7 (937) 275-35-15
                        </a>
                        <a
                            className="footer_sitemap_link_item"
                            href="https://yandex.ru/maps/195/ulyanovsk/house/orenburgskaya_ulitsa_42/YEEYcwRgSkQCQFtufX90dH1kYg==/?ll=48.530315%2C54.355176&source=serp_navig&z=17.14"
                            target="_blank"
                        >
                            г. Ульяновск, ул. Оренбургская,
                            42а
                        </a>
                        <Nav.Item className="footer_sitemap_contact_time">
                            Время работы: <br />
                            <p className="t16">
                                Пн-Вс: с 09:00 д 21:00
                            </p>
                        </Nav.Item>
                        <Nav.Item className="flex footer_sitemap_contact_icon_box">
                            <a target="_blanc" href="https://t.me/ivolga73" className="footer_sitemap_contact_icon flex">
                                <img src="/img/icon/tg.svg" alt="" />
                            </a>
                            <a target="_blanc" href="https://vk.com/ul_ivolga73" className="footer_sitemap_contact_icon flex">
                            <img src="/img/icon/vp.svg" alt="" />

                            </a>
                        </Nav.Item>
                    </Nav>
                </div>
            </Container>
        </footer>
    );
}
